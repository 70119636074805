<template>
	<div class="botonesMobile">
		<div class="row">
			<div class="col-2">
				<router-link :to="{name: 'comite_resumen_modelo'}" title="Resumen del Modelo">
					<resumen/>
				</router-link>
			</div>
			<div class="col-2">
				<router-link :to="{name: 'comite_garantia'}" title="Garantía">
					<garantia/>
				</router-link>
			</div>
			<div class="col-2">
				<router-link :to="{name: 'comite_comportamiento'}" title="Comportamiento">
					<comportamiento/>
				</router-link>
			</div>
			<div class="col-2 offset-1">
				<router-link :to="{name: 'comite_capacidad_pago'}" title="Capacidad de pago">
					<capacidad/>
				</router-link>
			</div>
			<div class="col-2">
				<router-link :to="{name: 'comite_decision_modelo'}" title="Decisión Modelo">
					<decision/>
				</router-link>
			</div>
      <!-- OPEN BANKING DESHABILITADO --->
      <!--<div class="col-2">
				<router-link :to="{name: 'open_banking'}" title="Open Banking">
					<img style="color:red" src="@/apps/comite/assets/img/open-banking.svg" alt="Open Banking" width="30px">
				</router-link>
			</div>-->
		</div>
	</div>
</template>

<script type="text/javascript">
	import capacidad from '@/apps/comite/components/icons/capacidad'
	import comportamiento from '@/apps/comite/components/icons/comportamiento'
	import decision from '@/apps/comite/components/icons/decision'
	import garantia from '@/apps/comite/components/icons/garantia'
	import resumen from '@/apps/comite/components/icons/resumen'

	export default {
		components: {
			capacidad,comportamiento,decision,garantia,resumen
		}
	}
</script>

<style lang="scss">
	.botonesMobile {
		width: 100%;
		background-color: #fff!important;
		margin: 0px auto;
		padding: 0px 15px;
		// overflow: auto;
		position: fixed;
		bottom: 0;
		left: 0;

		.row {
			.col-2 {
				margin: 15px auto;
				text-align: center;
				font-size: 0.6em;

				$svgSizeWidth: 30px;
				$svgSizeHeight: $svgSizeWidth;

				#svgCapacidad,#svgComportamiento,#svgDecision,#svgGarantia,#svgResumen {
					width: $svgSizeWidth;
					height: $svgSizeHeight;
				}
			}
		}
	}
	@media (min-width: 768px) {
		.botonesMobile {
			display: none;
		}
	}
</style>